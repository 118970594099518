import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router'

const ScrollToTop = ({ children }) => {
    const location = useLocation()
    useLayoutEffect(() => {
        console.log(location);
        //如果页面有hash，跳转到hash为止
        if (location.hash) {
            //获取元素id
            const id = location.hash.slice(1)
            //滚动到指定id的位置
            const element = document.getElementById(id)
            if (element) {
                element.scrollIntoView()
            }
        }else{
            document.documentElement.scrollTo(0, 0)

        }
    }, [location.pathname])
    return children
}

export default ScrollToTop
