import React from "react";
import Icon from "../Icon";
import './index.css';
import {useNavigate} from "react-router-dom";

function Button(props) {
    const {
        label,
        key,
        type,
        icon,
        size,
        color,
        url,
        handleClick,
    } = props;
    //判断type是不是以icon开头
    const isIcon = type.startsWith('icon');
    const navigator = useNavigate();
    if (isIcon) {
        return (
            <div
                key={key}
                className={`icon ${type}`}
                onClick={() => {
                    if (url === "call") {
                        window.location.href = 'tel:400 690 2288'
                    } else {
                        if (handleClick) {
                            handleClick()
                        } else {
                            if (url && url.includes('http')) {
                                window.location = url;
                            } else {
                                url && navigator(url)
                            }
                        }
                    }
                }}
            >
                {/*引入图标sousuo.svg*/}
                <Icon type={icon}/>
            </div>
        )
    } else {
        return (
            <div
                key={key}
                className={`button ${type} ${size ? size : ''}`}
                onClick={() => {
                    if (handleClick) {
                        //执行
                        console.log("handleClick执行了");
                        handleClick()
                    }
                    if (url === "call") {
                        window.location.href = 'tel:400 690 2288'
                    } else {
                        if (url && url.includes('http')) {
                            window.location = url;
                        } else {
                            url && navigator(url)
                        }
                    }

                }}
            >{label}
                {type === 'primary-arrow' &&
                    <Icon type={color === 'black' ? 'arrow-right-black' : 'arrow-right-green'}/>}
            </div>
        );
    }

}

export default Button;