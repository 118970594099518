import React from 'react';
import './index.css';
import Card from "../../components/Card/index.js";
const HistoryPage = () => {
    return (
        <>
            <div className="HistoryPage desktop">
                <img src="/assets/history/c1.png" className='banner1'/>
                <div className="card-container">
                    <Card width={650} height={200} title='查询您附近的门店' other='立即探索' bgImg='assets/about/c6.png' color="#FFF" titleSize={36}/>
                    <Card width={650} height={200} title='我们的服务' other='立即探索' bgImg='assets/about/c7.png' color="#FFF" titleSize={36}/>
                </div>
            </div>
            <div className="HistoryPage mobile">
                <img src="/assets/history/mobile-c1.png" className='banner1'/>
            </div>
        </>

    )
}
export default HistoryPage;
