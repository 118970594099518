import React from 'react';
import CardHeader from "../../../components/CardHeader";
import './index.css';
import {useNavigate} from "react-router-dom";
import {URL} from "../../../config/url.js";

const TinnitusPage = () => {
    const navigator = useNavigate()

    return (
        <div className="TinnitusPage">
            <CardHeader title="耳鸣管理"/>
            <img src="/assets/solution/tinnitus/c1.png" alt="" className='desktop' />
            <img src="/assets/solution/tinnitus/c1_mobile.png" alt="" className='mobile' />

            <div className="card-container">
                <img src="/assets/solution/tinnitus/c2.png" alt="" onClick={()=>{
                    window.location.href=URL.customerService;
                }}/>
            </div>
        </div>
    )
}
export default TinnitusPage;