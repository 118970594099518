import React from "react";
import CardHeader from "../../components/CardHeader/index.js";

import "./index.css";

const RegistrationTPage = () => {
  const currentDate = new Date(); // 获取当前日期的Date对象
  const specifiedDate = new Date("2024-04-22"); // 获取指定日期的Date对象
  const currentTime = currentDate.getTime(); // 获取当前日期的时间戳
  const specifiedTime = specifiedDate.getTime(); // 获取指定日期的时间戳

  if (currentTime < specifiedTime) {
    console.log("当前日期在指定日期之前");
    return (
        <div className="RegistrationPage">
          <CardHeader title="关于我们" />
          <div className="container">
            <img src="/assets/registration/t1.jpg" className="banner1" />
          </div>
          <div className="container">
            <img src="/assets/registration/t2.jpg" className="banner1" />
          </div>
        </div>
      );
  } else {
    console.log("当前日期与指定日期相同或在指定日期之后");
    return (
        <div className="RegistrationPage">
          <CardHeader title="关于我们" />
          <div className="container">
            <img src="/assets/registration/p1.jpg" className="banner1" />
          </div>
        </div>
      );
  }
//   return (
//     <div className="RegistrationPage">
//       <CardHeader title="关于我们" />
//       <div className="container">
//         <img src="/assets/registration/t1.jpg" className="banner1" />
//       </div>
//       <div className="container">
//         <img src="/assets/registration/t2.jpg" className="banner1" />
//       </div>
//     </div>
//   );
};
export default RegistrationTPage;
