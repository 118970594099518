import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation, Pagination} from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import './index.css'
import Button from "../Button/index.js";
import {URL} from "../../config/url.js";
import {useNavigate} from "react-router-dom";

SwiperCore.use([Navigation, Pagination]);


const Banner = () => {
    const navigator = useNavigate();
    return <div>
        <Swiper
            pagination={{
                type: 'bullets',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '"></span>';
                },

            }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={swiper => console.log(swiper)}

        >
            <SwiperSlide>
                <img src="/assets/index/banner/1.png" alt="" className='desktop'/>
                <Button label='立即探索' type='primary-green' handleClick={()=>{
                    navigator('/about')
                }}/>
                <img src="/assets/index/banner/mobile/1.png" alt="" className='mobile'/>
            </SwiperSlide>
            <SwiperSlide>
                <img src="/assets/index/banner/2.png" alt="" className='desktop'/>
                <Button label='立即探索' type='primary-green' handleClick={()=>{
                    navigator('/product')
                }}/>
                <img src="/assets/index/banner/mobile/2.png" alt="" className='mobile'/>
            </SwiperSlide>
        </Swiper>
    </div>
}
export default Banner;
