import React, {useEffect} from 'react';
import CardHeader from "../../../components/CardHeader";
import './index.css';
import {URL} from '../../../config/url';
import {useNavigate} from 'react-router-dom';
const AdultPage = () => {
    const navigator = useNavigate();
    return (
        <div className="AdultPage">
            <CardHeader title="成人听力解决方案"/>
            <img src="/assets/solution/adult/c1.png" alt="" className='desktop' />
            <img src="/assets/solution/adult/c1_mobile.png" alt="" className='mobile' />

            <div className="card-container">
                <img src="/assets/solution/adult/c2.png" alt="" onClick={()=>{
                    window.location.href = URL.test
                }}/>
                <img src="/assets/solution/adult/c3.png" alt="" onClick={()=>{
                    navigator('/product')
                }}/>
                <img src="/assets/solution/adult/c4.png" alt="" onClick={()=>{
                    navigator('/store-query')
                }}/>
            </div>
        </div>
    )
}
export default AdultPage;