import React from "react";
import Icon from "../../../components/Icon";
import './index.css';
import { useNavigate } from 'react-router-dom'

function GrayCard(props) {
    const {
        title,
        subTitle,
        type
    } = props;
    const navigate = useNavigate()
    return (
        <div className="GrayCard">
            <div className='card-header'>
                <div className='card-title'>{title}</div>
                <div className='card-subtitle'>{subTitle}</div>
            </div>
            <div className='card-other' onClick={() => {
                //react-router跳转到product/more页面
                navigate('/product-more?type='+type)
            }}>立即探索<Icon type='arrow-right-white'/></div>
        </div>
    )

}

export default GrayCard;
