import React, {useState} from 'react';
import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';
import './index.css';
import Button from '../Button';
import Icon from "../Icon";
import {useNavigate} from "react-router-dom";
import {URL} from "../../config/url.js";
import {statistics} from "../../config/util";

//按钮项
const desktopButtons = [
    {label: '加入会员', key: "vip", type: 'primary-ghost'},
    {label: '在线客服', key: URL.customerService, type: 'primary-ghost'},
    {label: '咨询热线：400 690 2288', key: 'call', type: 'primary-pure'}
]
const mobileButtons = [
    {label: '听力测试', key: URL.test, type: 'primary-ghost'},
    {label: '门店查询', key: 'store-query', type: 'primary-ghost'},
    {label: '加入会员', key: 'vip', type: 'primary-ghost'}
]

const mobileButtons2 = [
    {label: '在线客服', key: URL.customerService, type: 'primary-arrow',},
    {label: '咨询热线：400 690 2288', key: 'call', type: 'primary-arrow'}
]

const Nav = (props) => {
    //桌面端：控制subNav显示
    const [showSubNav, setShowSubNav] = useState(false);
    //桌面端：控制搜索栏显示
    const [showSearchBar, setShowSearchBar] = useState(false);
    //桌面端：子菜单数据项
    const [subItems, setSubItems] = useState([]);
    //移动端：控制导航栏的显示和隐藏
    const [showMobileNav, setShowMobileNav] = useState(false);
    //移动端：当前选中的菜单key
    const [openKey, setOpenKey] = useState('');
    const {items, search} = props;
    const [modalOpen, setModalOpen] = useState(false);
    const onOpenVipModal = () => setModalOpen(true);
    const onCloseVipModal = () => setModalOpen(false);
    const navigate = useNavigate()

    return (<>
        {showMobileNav && <div className="mask" onClick={() => {
            setShowMobileNav(false);
        }}/>}
        <div className="mainNav mobile">
            <Icon type='nav' handleClick={() => {
                setShowMobileNav(!showMobileNav);
            }}/>
            <div className="logo" onClick={() => {
                navigate('/');
            }}>
                <img src="/assets/logo.png" alt="" onClick={()=>{
                    setShowMobileNav(false);
                }}/>
            </div>
            <Icon type='contact' handleClick={() => {
                statistics({
                    actionType:"customerService"
                })
                window.location = URL.customerService;
            }}/>
        </div>

        {showMobileNav && <div className="subNav mobile">
            <div className="search">
                <input type="search" placeholder="搜索 audionova.com.cn" onKeyDown={(event) => {
                    //阻挡事件冒泡
                    event.stopPropagation();
                    if (event.keyCode === 13) {
                        window.location = '/search?query=' + event.target.value;
                    }
                }
                }/>
            </div>
            {items.map((item,index) => <div className="item"
                                      key={index}
                                      onClick={() => {
                                          if (item.jump !== 'no') {
                                              navigate('/' + item.key);
                                              setShowMobileNav(false);
                                          }
                                      }}>
                <div className='header'>
                    <div className='label'>
                        {item.label}
                    </div>
                    {item.subItems && <Icon type={openKey === item.key ? 'minus' : 'plus'} handleClick={(e) => {
                        //阻止事件冒泡
                        e.stopPropagation();
                        if (openKey === item.key) {
                            setOpenKey('');

                        } else {
                            setOpenKey(item.key);
                        }
                    }}/>}
                </div>
                {
                    openKey === item.key && <div className='subItems'>
                        {item.subItems && item.subItems.map((subItem,index) => <div
                            className='subItem'
                            key={index}
                            onClick={(e) => {
                                e.stopPropagation()
                                if(subItem.link){
                                    window.location = subItem.link;
                                }else{
                                    navigate('/' + subItem.key)
                                }
                                setShowMobileNav(false);
                            }}
                        >
                            {subItem.label}
                        </div>)}
                    </div>
                }
            </div>)}
            {/*//按钮区域*/}
            <div className="buttons" onClick={()=>{
                setShowMobileNav(false);
            }}>
                {mobileButtons.map((item,index) => {
                    if(item.key=== 'vip'){
                        return <Button
                            label={item.label}
                            key={index}
                            url={item.key}
                            type={item.type}
                            size='small'
                            handleClick={() => {
                                if (item.key === 'vip') {
                                    onOpenVipModal();
                                }
                            }}
                        />
                    }else{
                        return <Button
                            label={item.label}
                            key={index}
                            url={item.key}
                            type={item.type}
                            size='small'
                        />
                    }

                })}
            </div>

            <div className="buttons">
                {mobileButtons2.map((item,index) => <Button
                    label={item.label}
                    key={index}
                    url={item.key}
                    type={item.type}
                />)}
            </div>
        </div>}
        <div className="mainNav desktop">
            <div className="logo" onClick={() => {
                window.location = '/';
            }}>
                <img src="/assets/logo.png" alt=""/>
            </div>
            <div className="left">
                {items.map((item,index) => <div className="item"
                                          key={index}
                                          onMouseEnter={() => {
                                              if (item.subItems) {
                                                  setSubItems(item.subItems);
                                                  setShowSubNav(true);
                                              }
                                          }}
                                          onMouseLeave={() => setShowSubNav(false)}
                                          onClick={() => {
                                              if (item.jump !== 'no') {
                                                  navigate('/' + item.key)
                                              }

                                          }}><span>{item.label}</span></div>)}

            </div>
            <div className="right">
                {search && <Button type='icon-pure' icon="search" handleClick={() => {
                    setShowSearchBar(!showSearchBar);
                }}/>}
                {desktopButtons.map((item,index) => {
                    if (item.key === 'vip') {
                        return <Button
                            label={item.label}
                            key={index}
                            url={item.key}
                            type={item.type}
                            handleClick={() => {
                                if (item.key === 'vip') {
                                    onOpenVipModal();
                                }
                            }}
                        />
                    } else {
                        return <Button
                            label={item.label}
                            key={index}
                            url={item.key}
                            type={item.type}
                            handleClick={()=>{
                                if(item.key===URL.customerService){
                                    statistics({
                                        actionType:"customerService"
                                    })
                                }

                            }}
                        />
                    }
                })}
            </div>
        </div>
        {showSubNav &&
            <div
                className="subNav desktop"
                onMouseEnter={() => {
                    setShowSubNav(true);
                }}
                onMouseLeave={() => {
                    setShowSubNav(false);
                }}
            >
                {subItems.map((item,index) => <div className="item web" key={index} onClick={() => {
                    if (item.link) {
                        window.location = item.link
                    } else {
                        navigate('/' + item.key)
                    }
                }}>
                    <span>{item.label}</span>
                </div>)}
            </div>}
        {showSearchBar &&
            <div
                className="subNav desktop"
                onMouseEnter={() => {
                    setShowSubNav(true);
                }}
                onMouseLeave={() => {
                    setShowSubNav(false);
                }}
            >
                <div className='search'>
                    <Icon type='search'/>
                    <input type="search" placeholder="搜索 audionova.com.cn" onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                            window.location = '/search?query=' + event.target.value;
                        }
                    }
                    }/>
                </div>
            </div>}
        {/*加入会员模态框*/}
        <Modal open={modalOpen}
               onClose={onCloseVipModal}
               center
        >
            <img src="/assets/vip.png" width={300} alt=""/>
        </Modal>
    </>);
}

export default Nav;
