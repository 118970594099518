import React from 'react';
import CardHeader from "../../components/CardHeader/index.js";

import './index.css';

const InformationPage = () => {

    return (
        <div className="InformationPage">
            <CardHeader title="关于我们"/>
            <div className="container">
                <img src="/assets/information/i1.jpg" className='banner1'/>
            </div>
            {/* <div className="container">
                <img src="/assets/information/i2.jpg" className='banner1'/>
            </div> */}
        </div>
    )
}
export default InformationPage;
