import React from 'react';
import CardHeader from "../../components/CardHeader/index.js";

import './index.css';

const RecordPage = () => {

    return (
        <div className="RecordPage">
            <CardHeader title="关于我们"/>
            <div className="container">
                <img src="/assets/record/p1.jpg" className='banner1'/>
            </div>
        </div>
    )
}
export default RecordPage;
