import React from "react";
import Icon from "../../../components/Icon";
import './index.css';
import {useNavigate} from "react-router-dom";

function HearingCard(props) {
    const {
        position,
        storeImg
    } = props;
    const navigator=useNavigate()
    return (
        <div className="storeCard">
            <div className="left">
                <div className='card-header'>
                    <div className='card-title'>AudioNova傲诺瓦助听器</div>
                </div>
                <div className='card-other' onClick={() => {
                    navigator(`/store-detail?position=${position}`);
                }}>
                    {position}
                    <Icon type='arrow-right-green'/>
                </div>
            </div>
            <div className="right">
                <img src={storeImg} alt=""/>
            </div>

        </div>
    )

}

export default HearingCard;
