import React, {useEffect} from 'react';
import Button from "../../components/Button";
import {useSearchParams} from "react-router-dom";
import {getArticleById} from "../../config/util.js";
import {URL} from "../../config/url";
import './index.css';


const ArticlePage = () => {
    const [article, setArticle] = React.useState({});
    //获取文章id
    const [getParams] = useSearchParams();
    const id = getParams.get('id');
    useEffect(() => {
        if(id==="spring"){
            setArticle({
                title:"春节放假通知",
                //落地页图片
                content:`<img class="landingImg" src="https://qweqweqwe11.oss-cn-shanghai.aliyuncs.com/landingpage/year.png"/>`
            });

        }else{
            getArticleById(id).then(res => {
                const article = res.data.data.articleInfo;
                //如果tags是字符串
                if (typeof article.tags === 'string') {
                    //按逗号分隔
                    article.tags = article.tags.split(',');
                }
                setArticle(article);
            })
        }

    }, [])
    return (
        <>
            <div className="ArticlePage mobile">
                <div className='title'>{article.title}</div>
                <div className='tagContainer'>
                    {article.tags && article.tags.map(tag => {
                        return <Button label={tag} type="primary-ghost"/>
                    })}
                </div>

                <div className="content">
                    {/*富文本展示html*/}
                    <div dangerouslySetInnerHTML={{__html: article.content}}/>
                </div>
            </div>
            <div className="ArticlePage desktop">
                <div className='title'>{article.title}</div>
                <div className='tagContainer'>
                    {article.tags && article.tags.map(tag => {
                        return <Button label={tag} type="primary-ghost"/>
                    })}
                </div>
                <div className="content">
                    {/*富文本展示html*/}
                    <div dangerouslySetInnerHTML={{__html: article.content}}/>
                </div>
            </div>
        </>

    )
}
export default ArticlePage;
