import axios from "axios";
import {URL} from "./url.js";
//允许POST，PUT，DELETE请求
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true;



//获取文章列表
export const getArticleList = () => {
    const api = URL.articleList;
    //发送请求
    return axios.get(api)
}
//根据id获取文章内容
export const getArticleById = (id) => {
    const api = URL.articleId;
    //发送请求
    return axios.get(api+id)
}
//埋点
export const statistics = (params) => {
    const api = URL.statistics;
    //发送POST请求
    return axios.post(api,params)
}

//预约结果
export const order = (params) => {
    const api = URL.order;
    // params.isFamily转化为布尔值
    params.isFamily = params.isFamily === 'true';
    //发送POST请求
    return axios.post(api,params)
}