import React from "react";
import './index.css';
import Button from "../../../components/Button/index.js";
import {useNavigate} from "react-router-dom";

function HearingCardLarge(props) {
    let {
        title,
        id,
        tags=[],
        articleImg
    } = props;
    // 如果tags是字符串
    if (typeof tags === 'string') {
        //按逗号分隔
        tags = tags.split(',');
    }
    const navigate = useNavigate()
    return (
        <div className="hearingCardLarge" onClick={()=>{
            navigate('/article?id='+id)
        }}>
            <div className="top">
                <img src={articleImg} alt=""/>
            </div>
            <div className="bottom">
                <div className='card-header'>
                    <div className='card-title'>{title}</div>
                </div>
                <div className='card-other' onClick={() => {
                }}>
                    {tags && tags.map(tag => {
                        return <Button label={tag} type="primary-ghost"/>
                    })}
                </div>
            </div>


        </div>
    )

}

export default HearingCardLarge;