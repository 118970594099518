import React from "react";
import Icon from "../../../components/Icon";
import './index.css';
import {useNavigate} from "react-router-dom";

function SearchCard(props) {
    const {
        label,
        key,
        url,
        description,
    } = props;
    const navigator = useNavigate()
    console.log(key);
    return (
        <div className="SearchCard">
            <div className='card-header'>
                <div className='card-title'>{label}</div>
                <div className='card-subtitle'>{description}</div>
            </div>
            <div className='card-other' onClick={() => {
                //如果url包含http
                if (url && url.includes('http')) {
                    window.location = url;
                } else {
                    url && navigator('/' + url)
                }
            }}>
                立即探索
                <Icon type='arrow-right-green'/>
            </div>
        </div>
    )

}

export default SearchCard;