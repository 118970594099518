import React from "react";
import './index.css';

function ProductCard(props) {
    const {
        title,
        subTitle,
        other,
        rightImg,
    } = props;
    const [status] = React.useState("normal");
    return (
        <div className={`productCard ${status}`}>
                <div className="left">
                    <div className='card-header'>
                        <div className='card-title'>{title}</div>
                        <div className='card-subtitle'>{subTitle}</div>
                    </div>
                    <div className='card-other' onClick={() => {

                    }}>{other}</div>
                </div>
                <div className="right">
                    <img src={'/'+rightImg} alt=""/>
                </div>

        </div>
    )

}

export default ProductCard;