import React from 'react';
import CardHeader from "../../components/CardHeader/index.js";
import Card from "../../components/Card";
import './index.css';
import {useNavigate} from "react-router-dom";


const cardList = [
    {
        title: '听力解决方案',
        bgImg: 'assets/service/bg1.png',
        mobileImg: 'assets/service/bg1_mobile.png',
        url: '/listen'
    },
    {
        title: '服务标准',
        bgImg: 'assets/service/bg2.png',
        mobileImg: 'assets/service/bg2_mobile.png',
        url: '/service-standard'
    },
    {
        title: '专业设施',
        bgImg: 'assets/service/bg3.png',
        mobileImg: 'assets/service/bg3_mobile.png',
        color: '#fff',
        url: '/service-facility'
    },
    {
        title: '专家团队',
        bgImg: 'assets/service/bg4.png',
        mobileImg: 'assets/service/bg4_mobile.png',
        url: '/service-team'
    },
    {
        title: '特色服务',
        bgImg: 'assets/service/bg5.png',
        mobileImg: 'assets/service/bg5_mobile.png',
        url: '/service-feature'

    },
    {
        title: '私人定制',
        bgImg: 'assets/service/bg6.png',
        mobileImg: 'assets/service/bg6_mobile.png',
        url: '/service-custom'

    },
]
const ServicePage = () => {
    const navigator = useNavigate()
    return (
        <div className="ServicePage">
            <CardHeader title="我们的服务"/>
            <div className='card-container desktop'>
                {cardList.map(item => {
                    return <Card title={item.title} width={650} height={400} subTitle={item.subTitle} bgImg={item.bgImg}
                                 other="立即探索" color={item.color} url={item.url}/>
                })}
            </div>
            <div className='card-container mobile'>
                {cardList.map(item => {
                    return <img src={item.mobileImg} alt="" onClick={() => {
                        navigator(item.url)
                    }}/>
                })}
            </div>
        </div>
    )
}
export default ServicePage;
