import React, { useState } from "react";
import Icon from "../Icon";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import Button from "../Button/index";
import { nav } from "../../config/nav";
import { URL } from "../../config/url";

function Footer() {
  //二维码图片地址
  const [qrcodeImg, setQrcodeImg] = useState("");
  //移动端：当前选中的菜单key
  const [openKey, setOpenKey] = useState("");
  const navigate = useNavigate();
  return (
    <>
      <div className="footer">
        <div className="footer-container">
          {/*底部连接*/}
          {/* <div className='footer-link-container desktop'>
                    <div className="column">
                        <div className='footer-link'>
                            关于傲诺瓦
                        </div>
                        <Link to='/about' className='footer-link-sub'>
                            品牌介绍
                        </Link>
                        <Link to='/company' className='footer-link-sub'>
                            SONOVA集团
                        </Link>
                        <Link to='/history' className='footer-link-sub'>
                            集团历史
                        </Link>
                    </div>
                    <div className="column">
                        <Link to='/product' className='footer-link'>
                            我们的产品
                        </Link>
                        <Link to='/service' className='footer-link'>
                            我们的服务
                        </Link>
                        <Link to='/listen' className='footer-link-sub'>
                            听力解决方案
                        </Link>
                        <Link to='/service-standard' className='footer-link-sub'>
                            服务标准
                        </Link>
                        <Link to='/service-facility' className='footer-link-sub'>
                            专业设施
                        </Link>
                        <Link to='/service-team' className='footer-link-sub'>
                            专家团队
                        </Link>
                        <Link to='/service-feature' className='footer-link-sub'>
                            特色服务
                        </Link>
                        <Link to='/service-custom' className='footer-link-sub'>
                            私人定制
                        </Link>
                    </div>
                    <div className="column">
                        <div className='footer-link'>
                            我们的门店
                        </div>
                        <Link to='/store-query' className='footer-link-sub'>
                            门店查询
                        </Link>
                        <Link to='/store' className='footer-link-sub'>
                            全部门店列表
                        </Link>
                        <a href={URL.tmall} className='footer-link-sub'>
                            天猫商城
                        </a>
                        <a href={URL.jd} className='footer-link-sub'>
                            京东商城
                        </a>
                        <a href={URL.fl} className='footer-link-sub'>
                            峰力旗舰店
                        </a>
                    </div>
                    <div className="column">
                        <div className='footer-link'>
                            听力百科
                        </div>
                        <a href={URL.test} className='footer-link-sub'>
                            在线听力测试
                        </a>
                        <Link to='/knowledge' className='footer-link-sub'>
                            听力科普
                        </Link>
                        <Link to='/listener' className='footer-link-sub'>
                            助听器科普
                        </Link>
                    </div>
                    <div className="column">
                        <Link to='/activity' className='footer-link'>
                            活动中心
                        </Link>
                        <Link to='/contact' className='footer-link'>
                            联系我们
                        </Link>
                        <Link to='/job' className='footer-link'>
                            工作机会
                        </Link>
                    </div>
                </div> */}
          {/* <div className='footer-link-container subNav mobile'>
                    {nav.map((item) => <div className="item mobile"
                                            key={item.key}
                                            onClick={() => {
                                                if (item.jump !== 'no') {
                                                    navigate('/' + item.key)
                                                }
                                            }}>
                        <div className='header'>
                            <div className='label'>
                                {item.label}
                            </div>
                            {item.subItems && <Icon type={openKey === item.key ? 'minus' : 'plus'} handleClick={(e) => {
                                //阻止事件冒泡
                                e.stopPropagation();
                                if (openKey === item.key) {
                                    setOpenKey('');

                                } else {
                                    setOpenKey(item.key);
                                }
                            }}/>}
                        </div>
                        {
                            openKey === item.key && <div className='subItems'>
                                {item.subItems && item.subItems.map((subItem) => <div
                                    className='subItem'
                                    key={subItem.key}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (subItem.link) {
                                            window.location = subItem.link;
                                        } else {
                                            navigate('/' + subItem.key)

                                        }

                                    }}
                                >
                                    {subItem.label}
                                </div>)}
                            </div>
                        }
                    </div>)}
                </div> */}

          {/*底部按钮*/}
          {/* <div className='footer-button-container desktop'>
                    <div className='left'>
                        <Button label="在线客服" type="primary-arrow" url={URL.customerService}/>
                        <Button label="咨询热线：400 690 2288" type="primary-arrow" url='call'/>
                    </div>
                    <div className='right'>
                        <div className="qr-code">
                            <img src={qrcodeImg} alt=""/>
                        </div>
                        <div className='icon-circle'>
                            <Icon type='jd' url={URL.jd}/>
                        </div>
                        <div className='icon-circle'>
                            <Icon type='tmall' url={URL.tmall}/>
                        </div>
                        <div className='icon-circle' onClick={() => {
                            setQrcodeImg('/assets/footer/wechat.png')
                        }} onMouseLeave={() => {
                            setQrcodeImg('')
                        }}>
                            <Icon type='wechat'/>
                        </div>
                        <div className='icon-circle'>
                            <Icon type='zhihu' url={URL.zhihu}/>
                        </div>
                        <div className='icon-circle'>
                            <Icon type='weibo' url={URL.weibo}/>
                        </div>
                        <div className='icon-circle' onClick={() => {
                            console.log("执行了");
                            setQrcodeImg('/assets/footer/douyin.png')
                        }} onMouseLeave={() => {
                            setQrcodeImg('')
                        }}>
                            <Icon type='douyin'/>
                        </div>
                        <div className='icon-circle' onClick={() => {
                            setQrcodeImg('/assets/footer/xhs.png')
                        }} onMouseLeave={() => {
                            setQrcodeImg('')
                        }}>
                            <Icon type='xiaohongshu'/>
                        </div>
                    </div>
                </div> */}
          {/* <div className='footer-button-container mobile'>
                    <div className='left'>
                        <Button label="在线客服" type="primary-arrow" url={URL.customerService}/>
                        <Button label="咨询热线：400 690 2288" type="primary-arrow" url='call'/>
                    </div>
                    <div className='right'>
                        <div className="qr-code">
                            <img src={qrcodeImg} alt=""/>
                        </div>
                        <div className='icon-circle'>
                            <Icon type='jd' url={URL.jd}/>
                        </div>
                        <div className='icon-circle'>
                            <Icon type='tmall' url={URL.tmall}/>
                        </div>
                        <div className='icon-circle' onClick={() => {
                            setQrcodeImg('/assets/footer/wechat.png')
                        }} onMouseLeave={() => {
                            setQrcodeImg('')
                        }}>
                            <Icon type='wechat'/>
                        </div>
                        <div className='icon-circle'>
                            <Icon type='zhihu' url={URL.zhihu}/>
                        </div>
                        <div className='icon-circle'>
                            <Icon type='weibo' url={URL.weibo}/>
                        </div>
                        <div className='icon-circle' onClick={() => {
                            console.log("执行了");
                            setQrcodeImg('/assets/footer/douyin.png')
                        }} onMouseLeave={() => {
                            setQrcodeImg('')
                        }}>
                            <Icon type='douyin'/>
                        </div>
                        <div className='icon-circle' onClick={() => {
                            setQrcodeImg('/assets/footer/xhs.png')
                        }} onMouseLeave={() => {
                            setQrcodeImg('')
                        }}>
                            <Icon type='xiaohongshu'/>
                        </div>
                    </div>
                </div> */}
          {/*灰色下划线*/}
          <div className="footer-line" />
          {/*底部文字*/}
          <div className="footer-text-container desktop">
            {/* <div className='footer-text-left'>
                        <Link className='footer-text' to='/'>
                            隐私政策
                        </Link>
                        <Link className='footer-text' to='/'>
                            用户协议
                        </Link>
                        <Link className='footer-text' to='/'>
                            法律信息
                        </Link>
                        <Link className='footer-text' to='/'>
                            知识产权
                        </Link>
                    </div> */}
            <div className="footer-text-left"></div>
            <div className="footer-text-center">
              {/* <span className="footer-text">
                <Link to="/information" className="footer-link">
                  索诺瓦听见（上海）商贸有限公司
                </Link>
              </span> */}
              <span className="footer-text">
                <Link to="/information" className="footer-link">
                  索诺瓦听见（上海）商贸有限公司
                </Link>
                <Link to="/certificate" className="footer-link">
                  （沪）-非经营性-2020-0049
                </Link>
              </span>
              <span className="footer-text">
                <Link to="/record" className="footer-link">
                  沪徐药监械经营备20242003号
                </Link>
                <Link to="/registrationF" className="footer-link">
                  苏械注准20162191337
                </Link>
                <Link to="/registrationS" className="footer-link">
                  苏械注准20172191884
                </Link>
                <Link to="/registrationT" className="footer-link">
                  苏械注准20192190336
                </Link>
              </span>
              <span className="footer-text">
                © 2023 AudioNova All rights reserved. 沪ICP备20018236号-2
              </span>
            </div>
          </div>
          <div className="footer-text-container mobile">
            {/* <div className='footer-text-left'>
                        <Link className='footer-text' to='/privacy'>
                            隐私政策
                        </Link>
                        <Link className='footer-text' to='/'>
                            用户协议
                        </Link>
                        <Link className='footer-text' to='/'>
                            法律信息
                        </Link>
                        <Link className='footer-text' to='/'>
                            知识产权
                        </Link>
                    </div> */}
            <div className="footer-text-left"></div>
            <div className="footer-text-right">
              {/* <span className="footer-text">
                <Link to="/information" className="footer-link">
                  索诺瓦听见（上海）商贸有限公司
                </Link>
              </span> */}
              <span className="footer-text">
                <Link to="/information" className="footer-link">
                  索诺瓦听见（上海）商贸有限公司
                </Link>
                <Link to="/information" className="footer-link">
                  （沪）-非经营性-2020-0049
                </Link>
              </span>
              <span className="footer-text">
                <Link to="/record" className="footer-link">
                  沪徐药监械经营备20242003号
                </Link>
                <Link to="/certificate" className="footer-link">
                  苏械注准20162191337
                </Link>
                <Link to="/certificate" className="footer-link">
                  苏械注准20172191884
                </Link>
                <Link to="/certificate" className="footer-link">
                  苏械注准20192190336
                </Link>
              </span>
              <span className="footer-text">
                © 2023 AudioNova All rights reserved. 沪ICP备20018236号-2
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
