import React from 'react';
import Banner from "../../components/Banner";
import CardHeader from "../../components/CardHeader";
import Card from "../../components/Card";
import Button from "../../components/Button";
import {useNavigate} from "react-router-dom";
import SwiperCore, {Scrollbar} from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import {URL} from "../../config/url.js";
import './index.css';


SwiperCore.use([Scrollbar]);
const Index = ({

               }) => {
    const navigator = useNavigate();
    return (
        <div>
            <Banner/>
            <div className='indexContainer'>
                <CardHeader title="我们的产品" other='查看全部产品' url='/product'/>
                <div className="row mobile">
                    <img src="/assets/index/c1.png" alt="" onClick={() => {
                        navigator('/product#1')
                    }}/>
                    <img src="/assets/index/c2.png" alt="" onClick={() => {
                        navigator('/product#2')
                    }}/>
                </div>

                <div className="row desktop">
                    <Card width={650} height={650} title='PHONAK 峰力系列' subTitle='身临其境，宛若天籁。' other='立即探索'
                          bgImg='assets/card/index_bg1.png' url='/product#1'/>
                    <Card width={650} height={650} title='AudioNova 傲诺瓦系列' subTitle='核心科技，久经考验。' other='立即探索'
                          bgImg='assets/card/index_bg2.png' url='/product#2'/>
                </div>

                <CardHeader title="我们的服务" other='查看全部服务' url='/service'/>
                <div className="row mobile">
                    <img src="/assets/index/c3.png" alt="" onClick={() => {
                        navigator('/listen')
                    }}/>
                    <img src="/assets/index/c4.png" alt="" onClick={() => {
                        navigator('/service-standard')
                    }}/>
                    <img src="/assets/index/c5.png" alt="" onClick={() => {
                        navigator('/service-facility')
                    }}/>
                </div>

                <div className="row desktop">
                    <div className="left">
                        <Card width={650} height={800} title='听力解决方案' other='立即探索' bgImg='assets/card/index_bg3.png'
                              url='/listen'/>
                    </div>
                    <div className="right" style={{height: 800}}>
                        <Card width={650} height={390} title='服务标准' other='立即探索' bgImg='assets/card/index_bg4.png'
                              url='/service-standard'/>
                        <Card width={650} height={390} title='专业设施' other='立即探索' bgImg='assets/card/index_bg5.png'
                              color="#FFF" url='/service-facility'/>
                    </div>
                </div>
                <CardHeader title="听力百科"/>
                <div className="row mobile scroll-x">
                    <img src="/assets/index/c6.png" alt="" onClick={() => {
                        window.location.href = URL.test
                    }}/>
                    <img src="/assets/index/c7.png" alt="" onClick={() => {
                        navigator('/knowledge')
                    }}/>
                    <img src="/assets/index/c8.png" alt="" onClick={() => {
                        navigator('/listener')
                    }}/>
                </div>
                <div className="row desktop">
                    <Card width={426} height={300} title='在线听力测试' other='立即测试' bgImg='assets/card/index_bg6.png'
                          titleSize={32} url={URL.test}/>
                    <Card width={426} height={300} title='听力科普' other='立即了解' bgImg='assets/card/index_bg7.png'
                          color="#FFF" titleSize={32} url='/knowledge'/>
                    <Card width={426} height={300} title='助听器科普' other='立即了解' bgImg='assets/card/index_bg8.png'
                          color="#FFF" titleSize={32} url='/listener'/>
                </div>
            </div>
            <div className="mapContainer desktop">
                <div className="description">
                    <div className="title">
                        查询您附近的门店
                    </div>
                    <div className="buttonContainer">
                        <Button label="立即查询" type="primary-green" url='/store-query'/>
                        <div className="right">
                            <Button label="查看所有门店" type="primary-arrow" color='black' url='/store'/>
                        </div>
                    </div>
                </div>
                <div className="map">
                    <img src="/assets/index/map.png" alt=""/>
                </div>
            </div>
            <div className="mapContainer mobile">
                <div className="description">
                    <div className="title">
                        查询您附近的门店
                    </div>
                    <div className="buttonContainer">
                        <Button label="立即查询" type="primary-green" url='/store-query'/>
                        <div className="right">
                            <Button label="查看所有门店" type="primary-arrow" color='black' url='/store'/>
                        </div>
                    </div>
                </div>
                <div className="map">
                    <img src="/assets/index/map.png" alt=""/>
                </div>
            </div>
        </div>

    )
}
export default Index;
