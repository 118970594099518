import React, {useEffect} from 'react';
import CardHeader from "../../components/CardHeader/index.js";
import './index.css';
import {useNavigate} from "react-router-dom";

const FeaturePage = () => {
   const [pageWidth,setPageWidth]=React.useState(0);
    useEffect(()=>{
        //获取视口宽度
        let width = window.innerWidth;
        setPageWidth(width)
    })
    const navigator = useNavigate();

    return (
        <>
            <div className="FeaturePage mobile">
                <CardHeader title="特色服务"/>
                <img src="/assets/service/feature/c1_mobile.png" alt="" />
                <div className="card-container">
                    <img src="/assets/service/facility/c6.png" alt=""/>
                    <img src="/assets/service/feature/c4.png" alt=""/>
                </div>
            </div>
            <div className="FeaturePage desktop">
                <CardHeader title="特色服务"/>
                <img src="/assets/service/feature/c1.png" alt="" />
                <div className="description">
                    <div className='title'>飞行的听力师团队</div>
                    风雨无阻，注重实效。我们致力于为每一位顾客提供一站式的听力健康解决方案，无论是您自己还是您的家人存在任何听力问题，都能在这里得到专业可信赖的服务。
                </div>
                <div className="imgContainer" style={{width:pageWidth,height:1286}}>
                    <img src="/assets/service/feature/c2.png" alt=""/>
                </div>
                <div className="imgContainer" style={{width:pageWidth,height:805}}>
                    <img src="/assets/service/feature/c3.png" alt=""/>
                </div>

                <div className="card-container">
                    <img src="/assets/service/facility/c6.png" alt="" onClick={()=>{
                        navigator('/store-query')
                    }}/>
                    <img src="/assets/service/feature/c4.png" alt="" onClick={()=>{
                        navigator('/product')
                    }}/>
                </div>
            </div>
        </>

    )
}
export default FeaturePage;