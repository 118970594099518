import React, {useEffect} from 'react';
import CardHeader from "../../components/CardHeader/index.js";
import {useNavigate} from "react-router-dom";

import './index.css';

const CustomPage = () => {
    const navigator = useNavigate()

    const [pageWidth,setPageWidth]=React.useState(0);
    useEffect(()=>{
        //获取视口宽度
        let width = window.innerWidth;
        setPageWidth(width)
    })
    return (
        <>
            <div className="CustomPage mobile">
                <CardHeader title="私人定制"/>
                <img src="/assets/service/custom/c1_mobile.png" alt="" />

                <div className="card-container">
                    <img src="/assets/service/facility/c6.png" alt="" onClick={()=>{
                        navigator('/store-query')
                    }}/>
                    <img src="/assets/service/custom/c3.png" alt="" onClick={()=>{
                        navigator('/product')
                    }}/>
                </div>
            </div>

            <div className="CustomPage desktop">
                <CardHeader title="私人定制"/>
                <img src="/assets/service/custom/c1_d.png" alt="" />


                <div className="card-container">
                    <img src="/assets/service/facility/c6.png" alt="" onClick={()=>{
                        navigator('/store-query')
                    }}/>
                    <img src="/assets/service/custom/c3.png" alt="" onClick={()=>{
                        navigator('/product')
                    }}/>
                </div>
            </div>

        </>
    )
}
export default CustomPage;
