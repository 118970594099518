import React from 'react';
import CardHeader from "../../components/CardHeader/index.js";

import './index.css';

const CertificatePage = () => {

    return (
        <div className="CertificatePage">
            <CardHeader title="关于我们"/>
            <div className="container">
                <img src="/assets/certificate/p1.jpg" className='banner1'/>
            </div>
            <div className="container">
                <img src="/assets/certificate/p2.jpg" className='banner1'/>
            </div>
            <div className="container">
                <img src="/assets/certificate/p3.jpg" className='banner1'/>
            </div>
            <div className="container">
                <img src="/assets/certificate/p4.jpg" className='banner1'/>
            </div>
        </div>
    )
}
export default CertificatePage;
