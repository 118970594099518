import React from 'react';
import CardHeader from "../../components/CardHeader/index.js";

import './index.css';

const RegistrationSPage = () => {

    return (
        <div className="RegistrationPage">
            <CardHeader title="关于我们"/>
            <div className="container">
                <img src="/assets/registration/s1.jpg" className='banner1'/>
            </div>
            <div className="container">
                <img src="/assets/registration/s2.jpg" className='banner1'/>
            </div>
        </div>
    )
}
export default RegistrationSPage;
