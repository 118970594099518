import React, {useState} from 'react';
import './index.css';
import Button from "../../components/Button";
import {Modal} from "react-responsive-modal";
import {order} from "../../config/util.js";
import {countryCode} from "../../config/countryCode.js";

const AppointmentPage = () => {
    const [open, setOpen] = useState(false);
    //错误消息
    const [errorMessage, setErrorMessage] = useState('');
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    return (
        <div className="AppointmentPage">
            <img src="/assets/appointment/c1.png" className='banner desktop' alt=""/>
            <img src="/assets/appointment/c1_mobile.png" className='banner mobile' alt=""/>
            <div className="card-container desktop">
                <div className="title">
                    预约听力检测与产品体验
                </div>
                <input type='text' placeholder="姓名" name='name' className='input-name'/>
                <div className='input-container'>
                    <select name="regions" id="">
                        {
                            countryCode.map((item, index) => {
                             return <option key={index} value={item.phone_code}>{item.chinese_name} +{item.phone_code}</option>
                            })
                        }
                    </select>
                    <input type="text" placeholder='手机号' name='phone'/>
                </div>
                {/*单选框*/}
                <div className="radio-container">
                    <span>是否为家人咨询</span>
                    <label><input name="isFamily" type="radio" value="true"/>是</label>
                    <label><input name="isFamily" type="radio" value="false"/>否</label>
                </div>
                <Button label="立即预约" type='primary-green' handleClick={() => {
                    setErrorMessage('');

                    //获取input name=name的内容
                    const name = document.querySelector('.desktop input[name="name"]').value;
                    //获取input name=phone的内容
                    const phone = document.querySelector('.desktop input[name="phone"]').value;
                    //获取select name=regions的内容
                    const regions = document.querySelector('.desktop select[name="regions"]').value;
                    //获取input name=isFamily的内容
                    const isFamily = document.querySelector('.desktop input[name="isFamily"]:checked').value;
                    order({name, phone: regions + '' + phone, isFamily}).then(res => {
                        console.log(res);
                        if(res.data.errCode===0){
                            onOpenModal();
                        }else{
                            setErrorMessage(res.data.msg);
                        }
                    })

                }}/>
                <span>{errorMessage}</span>

            </div>
            <div className="card-container mobile">
                <div className="title">
                    预约听力检测与产品体验
                </div>
                <input type='text' placeholder="姓名" name='name' className='input-name'/>
                <div className='input-container'>
                    <select name="regions" id="">
                        {
                            countryCode.map((item, index) => {
                                return <option key={index} value={item.phone_code}>{item.chinese_name} +{item.phone_code}</option>
                            })
                        }
                    </select>
                    <input type="text" placeholder='手机号' name='phone'/>
                </div>
                {/*单选框*/}
                <div className="radio-container">
                    <span>是否为家人咨询</span>
                    <label><input name="isFamily" type="radio" value="true"/>是 </label>
                    <label><input name="isFamily" type="radio" value="false"/>否 </label>
                </div>
                <Button label="立即预约" type='primary-green' handleClick={() => {
                    setErrorMessage('');

                    //获取input name=name的内容
                    const name = document.querySelector('.mobile input[name="name"]').value;
                    //获取input name=phone的内容
                    const phone = document.querySelector('.mobile input[name="phone"]').value;
                    //获取select name=regions的内容
                    const regions = document.querySelector('.mobile select[name="regions"]').value;
                    //获取input name=isFamily的内容
                    const isFamily = document.querySelector('.mobile input[name="isFamily"]:checked').value;
                    console.log(name, regions, phone, isFamily);
                    order({name, phone: regions + '' + phone, isFamily}).then(res => {
                        console.log(res);
                        if(res.data.errCode===0){
                            onOpenModal();
                        }else{
                            setErrorMessage(res.data.msg);
                        }
                    })

                }}/>
                <span>{errorMessage}</span>
            </div>
            <Modal open={open} onClose={onCloseModal} center>
                <div className='modal-card'>
                    <img src="/assets/appointment/c2.png" alt="" onClick={()=>{
                        //跳转到客服页面
                        window.location.href = URL.customerService;
                    }}/>
                </div>
            </Modal>
        </div>
    )
}
export default AppointmentPage;