import React from 'react';
import CardHeader from "../../components/CardHeader/index.js";
import Card from "../../components/Card";
import {useNavigate} from "react-router-dom";
import './index.css';

const cardList = [
    {
        title: '成人听力解决方案',
        bgImg: 'assets/service/listen/bg1.png',
        mobileImg: 'assets/service/listen/bg1_mobile.png',
        url: '/solution-adult'
    },
    {
        title: '儿童听力解决方案',
        bgImg: 'assets/service/listen/bg2.png',
        mobileImg: 'assets/service/listen/bg2_mobile.png',
        url: '/solution-kid'
    },
    {
        title: '单侧耳聋解决方案',
        bgImg: 'assets/service/listen/bg3.png',
        mobileImg: 'assets/service/listen/bg3_mobile.png',
        url: '/solution-nshi'
    },
    {
        title: '耳鸣管理',
        bgImg: 'assets/service/listen/bg4.png',
        mobileImg: 'assets/service/listen/bg4_mobile.png',
        url: '/solution-tinnitus'
    },

]
const ListenPage = () => {
    const navigator = useNavigate()
    return (
        <div className="ListenPage">
            <CardHeader title="听力解决方案"/>
            <div className='card-container desktop'>
                {cardList.map(item => {
                    return <Card title={item.title} width={650} height={400} subTitle={item.subTitle} bgImg={item.bgImg}
                                 other="立即探索" url={item.url}/>
                })}
            </div>
            <div className='card-container mobile'>
                {cardList.map(item => {
                    return <img src={item.mobileImg} alt="" onClick={() => {
                        navigator(item.url)
                    }}/>
                })}
            </div>

            <div className="bottom-container desktop">
                <img src="/assets/service/listen/card1.png" alt="" onClick={() => {
                    navigator('/store-query')
                }}/>
                <img src="/assets/service/listen/card2.png" alt="" onClick={() => {
                    navigator('/product')
                }}/>
            </div>
            <div className="bottom-container mobile">
                <img src="/assets/service/listen/card1_mobile.png" alt="" onClick={() => {
                    navigator('/store-query')
                }}/>
                <img src="/assets/service/listen/card2_mobile.png" alt="" onClick={() => {
                    navigator('/product')
                }}/>
            </div>

        </div>
    )
}
export default ListenPage;
