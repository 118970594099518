import React, {useEffect} from 'react';
import CardHeader from "../../components/CardHeader/index.js";
import {useNavigate} from "react-router-dom";
import './index.css';

const FacilityPage = () => {
    const [pageWidth, setPageWidth] = React.useState(0);
    useEffect(() => {
        //获取视口宽度
        let width = window.innerWidth;
        setPageWidth(width)
    })
    const navigator = useNavigate();
    return (
        <>
            <div className="FacilityPage mobile">
                <CardHeader title="专业设施"/>
                <img src="/assets/service/facility/c1_mobile.png" alt=""/>
                <div className="card-container">
                    <img src="/assets/service/facility/c6.png" alt="" onClick={() => {
                        navigator('/store-query')
                    }}/>
                </div>
            </div>

            <div className="FacilityPage desktop">
                <CardHeader title="专业设施"/>
                <img src="/assets/store/1.png" alt=""/>
                <div className="description">
                    AudioNova傲诺瓦拥有行业领先的助听器验配设备，完善且尖端的验配工具帮助我们的验配师为不同听损程度的人士提供专业的一站式服务。
                </div>
                <div className="imgContainer" style={{width: pageWidth}}>
                    <img src="/assets/service/facility/c2.png" alt=""/>
                </div>
                <div className="imgContainer" style={{width: pageWidth}}>
                    <img src="/assets/service/facility/c3.png" alt=""/>
                </div>

                <div className="imgContainer" style={{width: pageWidth}}>
                    <img src="/assets/service/facility/c4.png" alt=""/>
                </div>

                <div className="imgContainer" style={{width: pageWidth}}>
                    <img src="/assets/service/facility/c5.png" alt=""/>
                </div>

                <div className="card-container">
                    <img src="/assets/service/facility/c6.png" alt="" onClick={() => {
                        navigator('/store-query')
                    }}/>
                </div>


            </div>

        </>
    )
}
export default FacilityPage;
