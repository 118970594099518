import React from 'react';
import Button from "../../components/Button";
import {useNavigate,useSearchParams} from "react-router-dom";
import {productList1,productList2} from "../Product/index.js";
import './index.css';

const ProductDetailPage = () => {
    const [getParams, setParam] = useSearchParams()
    const title = getParams.get('title');
    const type = getParams.get('type');
    let product;
    let typeLink;
    //遍历productList1
   productList1.map((item, index) => {
        //遍历item的children
       item.children.map((child, index) => {
            //如果child的title和title一致
           if (encodeURIComponent(child.title) === encodeURIComponent(title)&&encodeURIComponent(child.type) === encodeURIComponent(type)) {
                //返回child
                product= child;
               typeLink=1;
            }
        })
    })



    //如果没有找到就在productList2中查找
    if(!product){
        productList2.map((item, index) => {
            //遍历item的children
            if(item.children){
                item.children.map((child, index) => {
                    //如果child的title和title一致
                    if (encodeURIComponent(child.title) === encodeURIComponent(title)&&encodeURIComponent(child.type) === encodeURIComponent(type)) {
                        //返回child
                        product = child;
                        typeLink=2;
                    }
                })
            }

        })
    }
    const navigator=useNavigate()
    return (
        <div className="ProductDetailPage">
            <div className="ProductHeader mobile">
                <div className="ProductHeader-img">
                    <img src={'/'+product.rightImg} alt=""/>
                </div>
                <div className="ProductHeader-title">
                    <div className="ProductHeader-title-Container">
                        <div className='left'>
                            <div className="subtitle">
                                {product.type}
                            </div>
                            <div className="title">
                                {product.title}
                            </div>
                            <div className="model">
                                {product.subTitle}
                            </div>
                        </div>
                        <div className='right'>
                            <div className="price">
                                {/*RMB {product.price}起*/}
                            </div>
                        </div>
                    </div>
                    <div className="buttonContainer">
                        <Button label='查询门店' type="primary-pure" url='/store-query'/>
                        <Button label='预约试听' type='primary-green' url='/store-query'/>
                    </div>
                </div>

            </div>

            <div className="ProductHeader desktop">
                <div className="ProductHeader-title">
                    <div className="subtitle">
                        {product.type}
                    </div>
                    <div className="title">
                        {product.title}
                    </div>
                    <div className="model">
                        {product.subTitle}
                    </div>
                    <div className="price">
                        {/*RMB {product.price}起*/}
                    </div>
                    <div className="buttonContainer">
                        <Button label='查询门店' type="primary-pure"  url='/store-query'/>
                        <Button label='预约试听' type='primary-green' url='/appointment'/>
                    </div>
                </div>
                <div className="ProductHeader-img">
                    <img src={'/'+product.rightImg} alt=""/>
                </div>
            </div>
            <img src={"/"+product.contentD} alt="" className='content desktop'/>
            <img src={"/"+product.contentM} alt="" className='content mobile'/>
            <div className="card-container desktop">
                <img src="/assets/product/detail/1_card_left.png" alt="" onClick={()=>{
                    //跳转到产品列表页面
                    navigator('/product');
                }}/>
                <img src="/assets/product/detail/1_card_right.png" alt="" onClick={()=>{
                    //跳转到产品列表页面
                    navigator('/product-more?type='+typeLink);
                }}/>
            </div>
            <div className="card-container mobile">
                <img src="/assets/product/detail/1_card_left_mobile.png" alt="" onClick={()=>{
                    //跳转到产品列表页面
                    navigator('/product');
                }}/>
                <img src="/assets/product/detail/1_card_right_mobile.png" alt="" onClick={()=>{
                    //跳转到产品列表页面
                    navigator('/product-more?type='+typeLink);
                }}/>
            </div>
        </div>
    )
}
export default ProductDetailPage;
