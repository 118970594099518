import React from "react";
import './index.css';
import {useNavigate} from "react-router-dom";

function Icon(props) {
    const {type, handleClick, url} = props;
    const navigator = useNavigate();
    return (
        <img className='icon'
             src={`/assets/${type}.svg`}
             alt=""
             onClick={(e) => {
                 if (handleClick) {
                     handleClick(e)
                 } else {
                     if (url && url.includes('http')) {
                         window.location = url;
                     } else {
                         url && navigator(url)
                     }
                 }
             }}/>
    )

}

export default Icon;