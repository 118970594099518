import React from 'react';
import CardHeader from "../../components/CardHeader/index.js";
import {Link} from "react-router-dom";
import {URL} from "../../config/url";

import './index.css';

const ContactPage = () => {

    return (
        <div className="ContactPage">
            <CardHeader title="联系我们"/>
            <div className="container">
                <div className="title">
                    销售与产品查询
                </div>
                <div className="text-container">
                    <div className="left">
                        <div className="subTitle">
                            线上商城
                        </div>
                        <div className="text-container">
                            <p>访问我们的 <a href={URL.tmall}>天猫商城</a> 与 <a href={URL.jd}>京东商城</a> ，查看购买我们的产品与配件。若要通过电话咨询，请致电 <a href="tel:400 690 2288">400 690 2288</a>。
                            </p>
                        </div>
                    </div>
                    <div className="right">
                        <div className="subTitle">
                            线下门店
                        </div>
                        <div className="text-container">
                            <p>至全国任意一家AudioNova傲诺瓦门店体验享受聆听的快乐世界，<Link to='/store-query'>点此查询</Link>所有门店地址与营业详情。</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="title">
                    产品与技术支持
                </div>
                <div className="text-container">
                    <div className="left">
                        <div className="subTitle">
                            获得帮助
                        </div>
                        <div className="text-container">
                            <p>需要服务或支持？请致电 <a href="tel:400 690 2288">400 690 2288</a> 获取更多帮助，我们将为您联系听力师并找到解决方案。
                            </p>
                        </div>
                    </div>
                    <div className="right">
                        <div className="subTitle">
                            更多联系方式
                        </div>
                        <div className="text-container">
                            <ul>
                                <li>咨询热线：<a href="tel:400 690 2288">400 690 2288</a></li>
                                <li><Link to='/store-query'>查询所有门店信息</Link></li>
                                <li><Link to='/appointment'>预约听力检测与产品体验</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="title">
                    工作时间
                </div>
                <div className="text-container">
                    <div className="left">
                        <div className="subTitle">
                            线上客服
                        </div>
                        <div className="text-container">
                            <p>我们的在线客服与咨询热线工作时间为周一至周日的08:00至19:00，中国法定假日为客服休息时间，若您在这期间需要帮助，请 <a href={URL.customerService}>填写留言信息</a>，我们的客服将会在正常工作后第一时间联系您。
                            </p>
                        </div>
                    </div>
                    <div className="right">
                        <div className="subTitle">
                            更多联系方式
                        </div>
                        <div className="text-container">
                            <p>若您需要确认门店的营业时间与联系方式，请<Link to='/store-query'>查询门店列表</Link>。</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactPage;
