import React from 'react';
import './index.css';
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import {useSearchParams} from "react-router-dom";
import {storeList} from "../Store";

const StoreDetailPage = () => {
    //获取参数
    const [getParams] = useSearchParams()
    const position = getParams.get('position')
    //查找storeList中的position=position的项
    const store = storeList.find(item => item.position === position)
    return (
        <div className="StoreDetailPage">
            <img src={'/'+store.storeImg} alt="" className='store-img'/>

            <div className="storeHeader">
                傲诺瓦助听器{store.type} <span>{store.position}</span>
            </div>

            <div className="info">
                <div className='item' onClick={()=>{
                    //新打开空白页面
                    window.open(`https://ditu.amap.com/search?query=${store.address}`)
                }}>
                    <Icon type='position' />
                    {store.address}
                </div>
                <div className='item'>
                    <Icon type='time' />
                    {store.time}
                </div>
                <div className='item'>
                    <Icon type='men' />
                    {store.phone}
                </div>
            </div>
            <div className="map-container mobile">
                <img src={store.mapMobileImg?store.mapMobileImg:"/assets/store/detail/1_map_mobile.png"} alt=""/>
                <div>
                    <div className='position'>
                        <div><span>地铁</span>{store.subway}</div>
                        <div><span>公交</span>{store.bus}</div>
                    </div>
                    <Button label='联系门店' type="primary-green"  handleClick={()=>{
                        //拨打电话
                        window.location.href = `tel:${store.phone}`
                    }}/>
                </div>

            </div>
            <div className="map-container desktop">
                <img src={store.mapImg?store.mapImg:"/assets/store/detail/1_map.png"} alt=""/>
                <div>
                    <div className='position'>
                        <div><span>地铁</span>{store.subway}</div>
                        <div><span>公交</span>{store.bus}</div>
                    </div>
                    <div className='contact' onClick={()=>{
                        //拨打电话
                        window.location.href = `tel:${store.phone}`
                    }} >
                        <span>联系门店</span>
                        <span>{store.phone}</span>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default StoreDetailPage;